import React, { ReactNode, useContext, useEffect } from 'react';

import { MailFilled, PhoneFilled } from '@ant-design/icons';
import { Col, Layout as AntdLayout, Row, Space, Tag, Typography } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import LocalizedStrings from 'react-localization';
import styled from 'styled-components';

import { PrivacyPolicy } from '..';

const GATSBY_CLIENT_NAME = process.env.GATSBY_CLIENT_NAME || '';

const strings = new LocalizedStrings({
    en: {
        poweredBy: 'Powered by',
    },
    de: {
        poweredBy: 'Powered by',
    },
});
const { Content } = AntdLayout;
const GATSBY_STYLE_FORM_PAGE_FORM_LOCATION = process.env.GATSBY_STYLE_FORM_PAGE_FORM_LOCATION!;
const GATSBY_STYLE_EVENT_BG = process.env.GATSBY_STYLE_EVENT_BG!;
const GATSBY_STYLE_FORM_PAGE_FORM_BG = process.env.GATSBY_STYLE_FORM_PAGE_FORM_BG!;
const GATSBY_STYLE_EVENT_LOGO = process.env.GATSBY_STYLE_EVENT_LOGO!;
const GATSBY_STYLE_FORM_PAGE_FORM_OPACITY = process.env.GATSBY_STYLE_FORM_PAGE_FORM_OPACITY!;

const Background = styled.div`
    background: url('${GATSBY_STYLE_EVENT_BG}');
    background-color: ${GATSBY_CLIENT_NAME === 'siemens' ? '#000128' : undefined};
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    position: fixed;
`;

const FormArea = styled.div<{ breakpoint: Partial<Record<'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl', boolean>> }>`
    text-align: left;
    padding: ${props => (props.breakpoint.md ? '3' : '1')}rem;
    background-image: ${GATSBY_STYLE_FORM_PAGE_FORM_BG};
    border-radius: 4px;
    opacity: ${GATSBY_STYLE_FORM_PAGE_FORM_OPACITY ? GATSBY_STYLE_FORM_PAGE_FORM_OPACITY : 0.9};
`;

export const FormPageLayout = ({ children }: { children: ReactNode }) => {
    const breakpoint = useBreakpoint();
    return (
        <AntdLayout style={{ minHeight: '100vh' }}>
            <Background />
            <Content>
                <Row>
                    <Col
                        sm={24}
                        md={12}
                        style={{
                            backgroundColor: GATSBY_CLIENT_NAME === 'siemens' ? '#000128' : 'white',
                            width: '100vw',
                            opacity: 0.9,
                            paddingLeft: '1.5rem',
                            textAlign: breakpoint.md ? 'left' : 'center',
                        }}
                    >
                        <img
                            src={GATSBY_STYLE_EVENT_LOGO}
                            height="50"
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                        />
                    </Col>
                    <Col
                        sm={24}
                        md={12}
                        style={{
                            backgroundColor: GATSBY_CLIENT_NAME === 'siemens' ? '#000128' : 'white',
                            width: '100vw',
                            opacity: 0.9,
                            textAlign: 'right',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            paddingRight: '3rem',
                            paddingBottom: breakpoint.md ? undefined : '1rem',
                        }}
                    >
                        {!['siemens'].includes(GATSBY_CLIENT_NAME) && (
                            <Space>
                                <span>
                                    <PhoneFilled /> +1-619-298-6473
                                </span>
                                <span>
                                    <MailFilled /> info@virtooally.com
                                </span>
                            </Space>
                        )}
                    </Col>
                </Row>
                <Row
                    align="middle"
                    justify={
                        GATSBY_STYLE_FORM_PAGE_FORM_LOCATION === 'center'
                            ? 'space-around'
                            : GATSBY_STYLE_FORM_PAGE_FORM_LOCATION === 'left'
                            ? 'start'
                            : 'end'
                    }
                    style={{ padding: GATSBY_CLIENT_NAME === 'siemens' ? 0 : breakpoint.md ? '3rem' : '1rem' }}
                >
                    <Col xs={24} md={GATSBY_CLIENT_NAME === 'siemens' ? 12 : 8}>
                        <FormArea breakpoint={breakpoint}>{children}</FormArea>
                    </Col>
                </Row>

                <Row
                    align="middle"
                    justify={
                        !breakpoint.md
                            ? 'center'
                            : GATSBY_STYLE_FORM_PAGE_FORM_LOCATION === 'center'
                            ? 'space-around'
                            : GATSBY_STYLE_FORM_PAGE_FORM_LOCATION === 'left'
                            ? 'start'
                            : 'end'
                    }
                    style={{ paddingLeft: '3rem', paddingRight: '3rem' }}
                >
                    <Col
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {!['siemens'].includes(GATSBY_CLIENT_NAME) && (
                            <a href="https://www.virtooally.com/" target="_blank" rel="noreferrer">
                                <Tag>
                                    {strings.poweredBy} <b>Virtooally</b>
                                </Tag>
                            </a>
                        )}
                        <PrivacyPolicy />
                    </Col>
                </Row>
            </Content>
        </AntdLayout>
    );
};

export default FormPageLayout;
