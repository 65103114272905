import React, { useContext, useEffect, useState } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { Link } from 'gatsby';
import LocalizedStrings from 'react-localization';
import { StringParam, useQueryParam } from 'use-query-params';

import { ClientHelmet } from '../components';
import { FormPageLayout } from '../components/Layouts';
import { GlobalContext } from '../context/GlobalContextProvider';
import { apiRequester, handleError } from '../utility';

const { Title } = Typography;
const strings = new LocalizedStrings({
    en: {
        pleaseHold: 'Please hold while we verify your email',
        emailVerified: 'Your email has been verified. Please proceed to login.',
        couldNotVerify: 'We could not verify your email. Please try again. Reason: {0}',
        backToLogin: 'Back to Log in',
        success: 'Success!',
        oops: 'Oops!',
        verifying: 'Verifying...',
    },
    de: {
        pleaseHold: 'Bitte warten Sie, während wir Ihre E-Mail-Adresse verifizieren. ',
        emailVerified: 'Ihre E-Mail-Adresse wurde verifiziert. Bitte loggen Sie sich ein.',
        couldNotVerify:
            'Leider konnten wir Ihre E-Mail-Adresse nicht verifizieren. Bitte versuchen Sie es erneut. Grund: {0}',
        backToLogin: 'Zurück zum Login. ',
        success: 'Erfolgreich!',
        oops: 'Oops! Da ist etwas schiefgelaufen. ',
        verifying: 'Wird verifiziert...',
    },
});

const VerifyEmailPage = () => {
    const context = useContext(GlobalContext);
    const [status, setStatus] = useState<'success' | 'error' | 'pending'>('pending');
    const [token, setToken] = useQueryParam('t', StringParam);
    const [error, setError] = useState('');

    useEffect(() => {
        context
            .fetchEvent()
            .then(async ({ eventAuthModule }) => {
                if (eventAuthModule) await apiRequester.verifyEmail({ eventAuthModule, token: token || '' });
                setStatus('success');
            })
            .catch(err => {
                setStatus('error');
                if (err.code) setError(err.description || err.code);
                handleError(err);
            });
    }, []);

    return (
        <FormPageLayout>
            <ClientHelmet />
            <div style={{ width: '100%', textAlign: 'center' }}>
                <Title level={2} style={{ marginBottom: '1.5rem', color: 'white' }}>
                    {status === 'pending' ? strings.verifying : status === 'success' ? strings.success : strings.oops}
                </Title>
            </div>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    color: 'white',
                }}
            >
                {status === 'pending'
                    ? strings.pleaseHold
                    : status === 'success'
                    ? strings.emailVerified
                    : strings.formatString(strings.couldNotVerify!, error ? error : 'UNKNOWN')}
                <br />
                <br />
                <Link
                    style={{ color: 'white' }}
                    to={`/login${typeof window !== 'undefined' ? window.location.search : ''}`}
                >
                    <ArrowLeftOutlined /> {strings.backToLogin}
                </Link>
            </div>
        </FormPageLayout>
    );
};

export default VerifyEmailPage;
